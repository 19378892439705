import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import CountriesTable from 'components/countries/CountriesTable';
import Pagination from 'components/controls/Pagination';
// context - config
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from "context/TranslationProvider"
// services
import getCountries from 'services/getCountries';

const ShowCountries = () => {
  const auth = useContext(AuthProvider);
  const navigate = useNavigate();
  const { t } = useContext(TranslationProvider)

  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  /* eslint-disable */
  useEffect(() => {
    getCountries(auth.accesToken, currentPage, perPage)
      .then(response => {
        setCountries(response.data);
        setLastPage(response.last_page);
        setPerPage(response.per_page);
      })
      .catch(() => {
        navigate('/');
        window.sessionStorage.setItem('loggedNewIntranetAppUser', '');
        window.location.reload();
      });
  }, [currentPage]);

  const paginate = (currentPage) => {
    setCurrentPage(currentPage)
  }

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{t("countries.countries")}</span>
      </div>
      <CountriesTable countries={countries} />
      <Pagination
        lastPage={lastPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </div>
  );
};

export default ShowCountries;
