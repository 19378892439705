import React from 'react';

function ResponseDataTables({ queryresult }) {
  const { response, numeric_plan, participant, orgAssociation, mcc_mnc, search_time, "client ipAddress": clientIpAddress } = queryresult;

  const renderTable = (title, data) => {
    const rows = Object.entries(data).map(([key, value]) => (
      <tr key={key}>
        <td>{key}</td>
        <td>{value}</td>
      </tr>
    ));

    return (
      <div className="data-table table-responsive">
        <h3>{title}</h3>
        <table>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <div className="additional-info">
        <p><strong>Search Time:</strong> {search_time}</p>
        <p><strong>Client IP Address:</strong> {clientIpAddress}</p>
      </div>
      {response && renderTable("Response", response)}
      {mcc_mnc && renderTable("MCC/MNC", mcc_mnc)}
      {numeric_plan && renderTable("Numeric Plan", numeric_plan)}
      {participant && renderTable("Participant", participant)}
      {orgAssociation && renderTable("Org Association", orgAssociation)}

    </div>
  );
}

export default ResponseDataTables;
