import { URL } from './urls';

export default function getCustomersGraphs(accesToken, companyIds, countryIds, serviceIds) {
  const apiURL = URL + `customers_graphs`;

  let companyIdsForApi = companyIds.map(company => company.value);
  let countryIdsForApi = countryIds.map(country => country.value);
  let serviceIdsForApi = serviceIds.map(service => service.value);

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accesToken}` },
    body: JSON.stringify({
      company_ids: companyIdsForApi,
      country_ids: countryIdsForApi,
      service_ids: serviceIdsForApi,
    })
  };

  return fetch(apiURL, requestOptions)
    .then(res => res.json())
    .then(response => {
      return response;
    });
}
