import React from 'react'
import Select from "react-select"

const ServicesFilter = ({ serviceSelected, setServiceSelected }) => {

  const handlePerPageChange = (e) => {
    setServiceSelected(e.value)
  }

  return (
    <Select
      options={[
        { value: 'mnp', label: 'mnp' },
        { value: 'nri', label: 'nri' },
      ]}
      onChange={(e) => handlePerPageChange(e)}
      value={{ value: serviceSelected, label: serviceSelected }}
    />

  );
};

export default ServicesFilter