import { useContext } from 'react'

// components
import InterconnectionsTable from 'components/interconnections/InterconnectionsTable'
// context - config
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from "context/TranslationProvider"
// hooks
import useGetCustomers from 'hooks/useGetCustomers'

const ShowCustomers = () => {

  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)

  const { customers } = useGetCustomers(auth.accesToken)

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{t("interconnections")}</span>
      </div>

      <InterconnectionsTable
        users={customers}
      />
    </div>
  )
}

export default ShowCustomers