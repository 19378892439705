import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SalesTable from 'components/salesDetail/SalesTable';
import Pagination from 'components/controls/Pagination';
import PortabilitySearchBar from 'components/controls/searchBars/PortabilitySearchBar';
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from 'context/TranslationProvider';
import getSalesDetail from 'services/getSalesDetail';
import useSinceUntilDatePicker from 'hooks/useSinceUntilDatePicker';
import { salesInitialStartDate, salesInitialEndDate } from 'config/sinceUntilDatePicker';

const SalesDetail = () => {
  const auth = useContext(AuthProvider);
  const navigate = useNavigate();
  const { t } = useContext(TranslationProvider);

  const [sales, setSales] = useState([]);
  const [totals, setTotals] = useState({ total_transactions: 0, total_sales: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [servicesSelected, setServicesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);

  const { startDate, endDate, changeStartDate, changeEndDate } = useSinceUntilDatePicker({
    initialStartDate: salesInitialStartDate,
    initialEndDate: salesInitialEndDate,
  });

  /* eslint-disable */
  useEffect(() => {
    let companyIds = companiesSelected.map(company => company.value);
    const countryIds = countriesSelected.map(country => country.value);
    const serviceIds = servicesSelected.map(service => service.value);
    if (!auth.roles.includes("admin")) {
      companyIds = [auth.companyId]
    }
    getSalesDetail(auth.accesToken, currentPage, perPage, companyIds, countryIds, serviceIds, startDate, endDate)
      .then(response => {
        // console.log(response.total); // Verificar los datos recibidos
        setSales(response.data.data);
        setTotals(response.totals || { total_transactions: 0, total_sales: 0 });
        setLastPage(response.data.last_page);
        setPerPage(response.data.per_page);
      })
      .catch(() => {
        navigate('/');
        window.sessionStorage.setItem('loggedNewIntranetAppUser', '');
        window.location.reload();
      });
  }, [currentPage, companiesSelected, countriesSelected, servicesSelected, startDate, endDate]);

  const paginate = (currentPage) => {
    setCurrentPage(currentPage);
  }

  const manageExportToCsv = () => {
    let companyIds = companiesSelected.map(company => company.value);
    const countryIds = countriesSelected.map(country => country.value);
    const serviceIds = servicesSelected.map(service => service.value);
    if (!auth.roles.includes("admin")) {
      companyIds = [auth.companyId]
    }

    getSalesDetail(auth.accesToken, 1, perPage, companyIds, countryIds, serviceIds, startDate, endDate, false)
      .then(response => {
        const csvData = response.data.map(sale => {
          // Construir cada fila según el rol del usuario
          const row = {
            Fecha: sale.fecha,
            Teléfono: sale.phone,
            // Incluir 'Empresa' después de 'Teléfono' solo si el rol lo permite
            ...(auth.roles.includes("admin") ? { Empresa: sale.company_name } : {}),
            País: sale.country_name,
            Servicio: sale.service_name,
            Estado: sale.status,
            // Incluir 'Coste' después de 'Estado' solo si el usuario es admin
            ...(auth.roles.includes("admin") ? { Coste: sale.cost_price } : {}),
            Precio: sale.selling_price
          };
          return row;
        });

        // Construir el contenido CSV
        const csvContent = [
          Object.keys(csvData[0]).join(','), // Encabezados
          ...csvData.map(row => Object.values(row).join(',')), // Filas
        ].join('\n');

        // Generar y descargar el archivo CSV
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'sales_detail.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Error exporting data to CSV:', error);
      });
  };

  return (
    <Container fluid style={{ width: '70%', margin: '0 auto' }}>
      <div style={{ marginBottom: "30px", marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{auth.roles.includes("admin") ? t('sales.salesDetail') : t('sales.buyDetail')}</span>
      </div>
      <Row className="justify-content-center mb-3">
        <Col md="auto">
          <PortabilitySearchBar
            companiesSelected={companiesSelected}
            setCompaniesSelected={setCompaniesSelected}
            servicesSelected={servicesSelected}
            setServicesSelected={setServicesSelected}
            countriesSelected={countriesSelected}
            setCountriesSelected={setCountriesSelected}
            showDatePicker={true}
            startDate={startDate}
            changeStartDate={changeStartDate}
            endDate={endDate}
            changeEndDate={changeEndDate}
            setCurrentPage={setCurrentPage}
            showCsvButton={true}
            manageExportToCsv={manageExportToCsv} // Pasar función al PortabilitySearchBar 
          />
        </Col>
      </Row>
      <SalesTable sales={sales} totals={totals} />
      <Pagination
        lastPage={lastPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </Container>
  );
};

export default SalesDetail;
