import React, { forwardRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SinceUntilDatePicker = ({ setCurrentPage, startDate, changeStartDate, endDate, changeEndDate }) => {

  const handleDatePicker1 = (e) => {
    changeStartDate({ startDate: e });
    setCurrentPage(1);
  }

  const handleDatePicker2 = (e) => {
    changeEndDate({ endDate: e });
    setCurrentPage(1);
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-secondary" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <>
      <Row className="justify-content-md-center mb-3">
        <Col md="auto">
          <DatePicker
            selected={startDate}
            onChange={(e) => handleDatePicker1(e)}
            dateFormat="dd / MM / yyyy"
            customInput={<ExampleCustomInput />}
            excludeDateIntervals={endDate ? [{ start: endDate, end: new Date(endDate.getTime() + 86400000 * 100) }] : []}
          />
        </Col>
        <Col md="auto">
          <DatePicker
            selected={endDate}
            onChange={(e) => handleDatePicker2(e)}
            dateFormat="dd / MM / yyyy"
            customInput={<ExampleCustomInput />}
            excludeDateIntervals={startDate ? [{ start: new Date(startDate.getTime() - 86400000 * 1000), end: new Date(startDate.getTime() - 86400000) }] : []}
          />
        </Col>
      </Row>
    </>
  )
}

export default SinceUntilDatePicker;
