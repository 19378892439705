import React, { useState, useEffect, useContext } from 'react';
import Select from "react-select";
import AuthProvider from 'context/AuthProvider';
import getCountriesForFilter from 'services/getCountriesForFilter';
import TranslationProvider from 'context/TranslationProvider';

const CountriesFilter = ({ countriesSelected, setCountriesSelected }) => {
  const auth = useContext(AuthProvider);
  const [countries, setCountries] = useState([]);
  const { t } = useContext(TranslationProvider);

  useEffect(() => {
    getCountriesForFilter(auth.accesToken).then(countries => {
      const formattedCountries = countries.map(country => ({
        label: country.country_name,
        value: country.id
      }));
      setCountries(formattedCountries);
    });
  }, [auth.accesToken]);

  const handleCountriesChange = (selectedOptions) => {
    setCountriesSelected(selectedOptions);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: '150px',
    }),
  };

  return (
    <Select
      options={countries}
      onChange={handleCountriesChange}
      value={countriesSelected}
      styles={customStyles}
      placeholder={t('admin.countries')}
      isMulti
    />
  );
};

export default CountriesFilter;
