import React, { useContext } from 'react'
import TranslationProvider from 'context/TranslationProvider'
import './styles.css'; // Estilos CSS para el borde redondeado
import Container from 'react-bootstrap/Container';
import AuthProvider from "context/AuthProvider"

const Home = () => {

  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)


  return (
    <Container fluid style={{ width: '70%', margin: '0 auto' }}>
      <div style={{ marginBottom: "30px", marginTop: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{t("wellcome")}, {auth.userName}</span>
      </div>
    </Container>
  )
}


export default Home