import { URL } from './urls';

export default function getCountriesForFilter(accessToken) {
  const apiURL = URL + 'countries_for_filter';

  return fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error('Error fetching countries for filter:', error);
      throw error;
    });
}
