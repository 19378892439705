import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthProvider from 'context/AuthProvider'
import getUser from 'services/getUser'
import updateUser from 'services/updateUser'
import EditPriceForm from './EditPriceForm'

const EditPrice = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { id } = useParams()
  const [formParameters, setFormParameters] = useState({
    name: '',
    phone: '',
    email: '',
    language: [],
    perPage: [],
    twoFACode: ''
  })
  const [errMsg, setErrMsg] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const update = async () => {
    //e.preventDefault();
    setShowSpinner(true);

    //console.log(formParameters)
    updateUser(auth.accesToken, id, formParameters)
      .then(response => {
        if (!response.status) {
          setErrMsg(response.message)
        }
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })

    setTimeout(() => {
      setShowSpinner(false);
      navigate('/prices')
    }, 2000)

  }

  const goBack = async (e) => {
    e.preventDefault();
    navigate('/prices')
  }

  /* eslint-disable */
  useEffect(() => {
    getUser(auth.accesToken, id)
      .then(response => {
        if (!response.status) {
          setErrMsg(response.message)
        }
        setFormParameters({
          ...formParameters,
          id: response.id,
          name: response.name,
          phone: response.phone,
          email: response.email,
          language: {
            label: response.language ? response.language : null,
            value: response.language ? response.language : null
          },
          perPage: {
            label: response.per_page ? response.per_page : null,
            value: response.per_page ? response.per_page : null
          },
          twoFACode: response.isNeeded2FA,
        })
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [])

  // I have to wait for the perPage to load before randerizing
  if (formParameters.perPage.length === 0 || formParameters.language.length === 0) return;

  return (
    <EditPriceForm
      formParameters={formParameters}
      setFormParameters={setFormParameters}
      goBack={goBack}
      update={update}
      errMsg={errMsg}
      showSpinner={showSpinner}
    />
  )
};
export default EditPrice;