
import React, { useContext } from 'react';

import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import TranslationProvider from '../../context/TranslationProvider'
import ServicesFilter from "./ServicesFilter";
//import CustomersFilter from "components/controls/filters/CustomersFilter";

const QuerySearchBar = (props) => {

  const { t } = useContext(TranslationProvider)

  const clearPage = () => {
    props.setNumberToQuery('')
    //props.setCustomerSelected({ label: "Customer 1", value: 8 })
    props.setServiceSelected('mnp')
    props.setSendQuery(false)
    props.setQueryResult([])

  }

  const sendQuery = () => {
    props.setSendQuery(true)
  }

  return (
    <div style={{ padding: "20px" }}>
      <Container>
        <Form>
          <Row className="justify-content-md-center mb-3">
            <Col md="auto">
              <Form.Control
                onChange={(e) => {
                  props.setNumberToQuery(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    sendQuery();
                  }
                }}
                placeholder={t('search_phone')}
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={props.numberToQuery}
              />
            </Col>
            {/* <Col md="auto">
              <CustomersFilter
                customerSelected={props.customerSelected}
                setCustomerSelected={props.setCustomerSelected}
              />
            </Col> */}
            <Col md="auto">
              <ServicesFilter
                serviceSelected={props.serviceSelected}
                setServiceSelected={props.setServiceSelected}
              />
            </Col>
            <Col md="auto">
              <Button
                type="button"
                variant="primary"
                onClick={clearPage}
              >
                {t('clean')}
              </Button>
            </Col>
            <Col md="auto">
              <Button
                type="button"
                variant="primary"
                onClick={sendQuery}
              >
                {t('submit')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );


};

export default QuerySearchBar;