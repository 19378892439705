import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import TranslationProvider from 'context/TranslationProvider';
import AuthProvider from 'context/AuthProvider';
import CompaniesFilter from 'components/controls/filters/CompaniesFilter';
import ServicesFilter from 'components/controls/filters/ServicesFilter';
import CountriesFilter from 'components/controls/filters/CountriesFilter';

const PricesSearchBar = ({
  companiesSelected,
  setCompaniesSelected,
  servicesSelected,
  setServicesSelected,
  countriesSelected,
  setCountriesSelected,
  setCurrentPage = () => { },
}) => {
  const { t } = useContext(TranslationProvider);
  const auth = useContext(AuthProvider);

  const clearFilters = () => {
    setCompaniesSelected([]);
    setCountriesSelected([]);
    setServicesSelected([]);
    setCurrentPage(1);
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-center mb-3">
          {!(auth.roles.includes("owner") && !auth.roles.includes("admin")) &&
            <Col xs="auto">
              <CompaniesFilter
                companiesSelected={companiesSelected}
                setCompaniesSelected={setCompaniesSelected}
              />
            </Col>}
          <Col xs="auto">
            <CountriesFilter
              countriesSelected={countriesSelected}
              setCountriesSelected={setCountriesSelected}
            />
          </Col>
          <Col xs="auto">
            <ServicesFilter
              servicesSelected={servicesSelected}
              setServicesSelected={setServicesSelected}
            />
          </Col>
          <Col xs="auto">
            <Button
              type="button"
              variant="primary"
              onClick={clearFilters}
            >
              {t('clean')}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PricesSearchBar;
