import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

// components
import CompaniesTable from 'components/companies/CompaniesTable';
import Pagination from 'components/controls/Pagination';
// context - config
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from 'context/TranslationProvider';
// services
import getCompanies from 'services/getCompanies';

const ShowCompanies = () => {
  const auth = useContext(AuthProvider);
  const navigate = useNavigate();
  const { t } = useContext(TranslationProvider);

  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  /* eslint-disable */
  useEffect(() => {
    getCompanies(auth.accesToken, currentPage, perPage)
      .then(response => {
        setCompanies(response.data);
        setLastPage(response.last_page);
        setPerPage(response.per_page);
      })
      .catch(() => {
        navigate('/');
        window.sessionStorage.setItem('loggedNewIntranetAppUser', '');
        window.location.reload();
      });
  }, [currentPage]);

  const paginate = (currentPage) => {
    setCurrentPage(currentPage);
  }

  return (
    <Container fluid style={{ width: '70%', margin: '0 auto' }}>
      <div style={{ marginBottom: "30px", marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{t("companies.companies")}</span>
      </div>
      <CompaniesTable companies={companies} />
      <Pagination
        lastPage={lastPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </Container>
  );
};

export default ShowCompanies;
