import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from 'context/TranslationProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { FormLabel, FormButton2, FormError, FormHead } from 'styles/form';
import getCompanyById from 'services/getCompanyById';
import updateCompany from 'services/updateCompany';

const schema = yup.object({
  name: yup.string().min(2).required(),
  legal_name: yup.string().min(2).required(),
  tax_id: yup.string().required(),
  address1: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip_code: yup.string().required(),
  country: yup.string().required(),
  contact_number: yup.string().required() // Seguimos usando contact_number aquí
}).required();

const EditCompanyForm = () => {
  const { id } = useParams();
  const auth = useContext(AuthProvider);
  const { t } = useContext(TranslationProvider);
  const navigate = useNavigate();

  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    getCompanyById(auth.accesToken, id)
      .then(response => {
        setCompany(response);
        reset({
          name: response.name,
          legal_name: response.legal_name,
          tax_id: response.tax_id,
          address1: response.location.address1,
          city: response.location.city,
          state: response.location.state,
          zip_code: response.location.zip_code,
          country: response.location.country,
          contact_number: response.location.contact_number // Seguimos usando contact_number aquí
        });
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [id, reset, auth.accesToken]);

  const onSubmit = (data) => {
    setShowSpinner(true);
    updateCompany(auth.accesToken, id, data)
      .then(() => navigate('/admin/companies'))
      .catch(error => {
        console.error(error);
        setShowSpinner(false);
      });
  };

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{t('error_loading_company')}</p>;
  if (!company) return <p>{t('company_not_found')}</p>;

  return (
    <div style={{ padding: 20 }}>
      {showSpinner && (
        <Spinner
          animation="border" variant="primary"
          style={{ "display": "grid", "margin": "auto" }}
        />
      )}
      <FormHead>{t('companies.edit_company')}</FormHead>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormLabel>{t('companies.name')}</FormLabel>
          <input
            {...register("name")}
            defaultValue={company.name}
            type='text'
            className='form-control'
          />
          {errors.name && <FormError mError>{errors.name.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('companies.legal_name')}</FormLabel>
          <input
            {...register("legal_name")}
            defaultValue={company.legal_name}
            type='text'
            className='form-control'
          />
          {errors.legal_name && <FormError mError>{errors.legal_name.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('companies.tax_id')}</FormLabel>
          <input
            {...register("tax_id")}
            defaultValue={company.tax_id}
            type='text'
            className='form-control'
          />
          {errors.tax_id && <FormError mError>{errors.tax_id.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('locations.address1')}</FormLabel>
          <input
            {...register("address1")}
            defaultValue={company.location.address1}
            type='text'
            className='form-control'
          />
          {errors.address1 && <FormError mError>{errors.address1.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('locations.city')}</FormLabel>
          <input
            {...register("city")}
            defaultValue={company.location.city}
            type='text'
            className='form-control'
          />
          {errors.city && <FormError mError>{errors.city.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('locations.state')}</FormLabel>
          <input
            {...register("state")}
            defaultValue={company.location.state}
            type='text'
            className='form-control'
          />
          {errors.state && <FormError mError>{errors.state.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('locations.zip_code')}</FormLabel>
          <input
            {...register("zip_code")}
            defaultValue={company.location.zip_code}
            type='text'
            className='form-control'
          />
          {errors.zip_code && <FormError mError>{errors.zip_code.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('locations.country')}</FormLabel>
          <input
            {...register("country")}
            defaultValue={company.location.country}
            type='text'
            className='form-control'
          />
          {errors.country && <FormError mError>{errors.country.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('locations.phone')}</FormLabel>
          <input
            {...register("contact_number")} // Seguimos usando contact_number aquí
            defaultValue={company.location.contact_number} // Seguimos usando contact_number aquí
            type='text'
            className='form-control'
          />
          {errors.contact_number && <FormError mError>{errors.contact_number.message}</FormError>}
        </div>

        <h4 className={error ? "errmsg" : "offscreen"} aria-live="assertive">{error}</h4>

        <FormButton2 type="submit">{t('actions.save')}</FormButton2>
        <FormButton2 type="button" onClick={() => navigate('/admin/companies')}>{t('actions.cancel')}</FormButton2>
      </form>
    </div>
  );
};

export default EditCompanyForm;
