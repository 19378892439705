import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import TranslationProvider from 'context/TranslationProvider';
import { Tr, Td, Thead, Th, Tbody } from 'styles/table';
import { AiFillEdit } from 'react-icons/ai';

const CountriesTable = (props) => {
  const { t } = useContext(TranslationProvider);

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            <Th>{t('countries.name')}</Th>
            <Th>{t('countries.numeric_plan_entries')}</Th>
            <Th>{t('countries.numeric_plan_cost')}</Th>
            <Th>{t('actions.edit')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.countries.map((country) => (
            <Tr key={country.id}>
              <Td type="center"> {country.country_name} </Td>
              <Td type="center"> {country.numeric_plan_entries} </Td>
              <Td type="center"> {country.numeric_plan_cost} </Td>
              <Td type="center">
                <Link to={`/admin/countries/edit/${country.id}`}>
                  <AiFillEdit />
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default CountriesTable;
