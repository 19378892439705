import React from "react"
import { Link } from "react-router-dom"
import { Nav } from "react-bootstrap"

const SharedMenu = ({ t }) => {
  return (
    <Nav.Link as={Link} to="/prices">
      {t("prices.prices")}
    </Nav.Link>
  )
}

export default SharedMenu
