import { URL } from './urls';

export default function getUsers(accesToken, currentPage, perPage) {
  const apiURL = URL + `users?page=${currentPage}&per_page=${perPage}`

  return fetch(apiURL, {
    headers: {
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response
    })
}