import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
//import { Link } from 'react-router-dom'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"
import TranslationProvider from "context/TranslationProvider"

const OtherServicesTable = (props) => {
  const { t } = useContext(TranslationProvider)

    // Calcular el coste total
    const totalCost = props.databases.reduce((total, database) => total + database.price_monthly, 0);

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>{t("digitalOcean.name")}</Th>
            <Th>{t("digitalOcean.type")}</Th>
            <Th>{t("digitalOcean.version")}</Th>
            <Th>{t("digitalOcean.num_nodes")}</Th>
            <Th>{t("digitalOcean.slug")}</Th>
            <Th>{t("digitalOcean.price_monthly")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.databases.map((database) => (
            <Tr key={database.name}>
              <Td type="center" style={{ padding: "3px" }}> {database.name} </Td>
              <Td type="center" style={{ padding: "3px" }}> {database.engine} </Td>
              <Td type="center" style={{ padding: "3px" }}> {database.semantic_version} </Td>
              <Td type="center" style={{ padding: "3px" }}> {database.num_nodes} </Td>
              <Td type="center" style={{ padding: "3px" }}> {database.slug} </Td>
              <Td type="center" style={{ padding: "3px" }}> {database.price_monthly} &nbsp; $/{t("month")} </Td>
              {/* <Td style={{  "backgroundColor": "transparent" }}> </Td> */}
            </Tr>
          ))}
          <Tr>
            <Td type="center" colSpan="5"><strong>Total</strong></Td>
            <Td type="center"><strong>{totalCost} &nbsp; $/{t("month")}</strong></Td>
          </Tr>
        </Tbody>
      </Table>

    </div>
  )
}

export default OtherServicesTable