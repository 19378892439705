import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from 'context/TranslationProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { FormLabel, FormButton2, FormError, FormHead } from 'styles/form';
import getCountryById from 'services/getCountryById';
import updateCountry from 'services/updateCountry';

const schema = yup.object({
  country_name: yup.string().min(2).required(),
  numeric_plan_entries: yup.number().required(),
  numeric_plan_cost: yup
    .number()
    .test(
      'is-decimal',
      'The numeric_plan_cost must have at most 6 decimal places and be less than 1',
      value => (value !== undefined && value !== null) ? /^\d*(\.\d{1,6})?$/.test(value.toString()) && value < 1 : false
    )
    .required('numeric_plan_cost is required')
}).required();

const EditCountryForm = () => {
  const { id } = useParams();
  const auth = useContext(AuthProvider);
  const { t } = useContext(TranslationProvider);
  const navigate = useNavigate();

  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    getCountryById(auth.accesToken, id)
      .then(response => {
        setCountry(response);
        reset(response);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [id, reset, auth.accesToken]);

  const onSubmit = (data) => {
    setShowSpinner(true);
    updateCountry(auth.accesToken, id, data)
      .then(() => navigate('/admin/countries'))
      .catch(error => {
        console.error(error);
        setShowSpinner(false);
      });
  };

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{t('error_loading_country')}</p>;
  if (!country) return <p>{t('country_not_found')}</p>;

  return (
    <div style={{ padding: 20 }}>
      {showSpinner && (
        <Spinner
          animation="border" variant="primary"
          style={{ "display": "grid", "margin": "auto" }}
        />
      )}
      <FormHead>{t('countries.edit_country')}</FormHead>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormLabel>{t('countries.name')}</FormLabel>
          <input
            {...register("country_name")}
            defaultValue={country.country_name}
            type='text'
            className='form-control'
            readOnly
          />
          {errors.country_name && <FormError mError>{errors.country_name.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('countries.numeric_plan_entries')}</FormLabel>
          <input
            {...register("numeric_plan_entries")}
            defaultValue={country.numeric_plan_entries}
            type='number'
            className='form-control'
          />
          {errors.numeric_plan_entries && <FormError mError>{errors.numeric_plan_entries.message}</FormError>}
        </div>

        <div>
          <FormLabel>{t('countries.numeric_plan_cost')}</FormLabel>
          <input
            {...register("numeric_plan_cost")}
            defaultValue={country.numeric_plan_cost}
            type='number'
            step="0.000001"
            className='form-control'
          />
          {errors.numeric_plan_cost && <FormError mError>{errors.numeric_plan_cost.message}</FormError>}
        </div>

        <h4 className={error ? "errmsg" : "offscreen"} aria-live="assertive">{error}</h4>

        <FormButton2 type="submit">{t('actions.save')}</FormButton2>
        <FormButton2 type="button" onClick={() => navigate('/admin/countries')}>{t('actions.cancel')}</FormButton2>
      </form>
    </div>
  );
};

export default EditCountryForm;
