import React, { useEffect, useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from "react-bootstrap/Spinner";
import Chart from 'components/salesGraphs/Chart';
import getCustomersGraphs from 'services/getCustomersGraphs';
import TranslationProvider from 'context/TranslationProvider';
import AuthProvider from 'context/AuthProvider';
import PortabilitySearchBar from 'components/controls/searchBars/PortabilitySearchBar';

const CustomersSales = () => {
  const { t } = useContext(TranslationProvider);
  const { i18n } = useContext(TranslationProvider);
  const auth = useContext(AuthProvider);

  const [customersSales, setCustomersSales] = useState([]);
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [servicesSelected, setServicesSelected] = useState([]);

  /* eslint-disable */
  useEffect(() => {
    // Mostrar el spinner antes de iniciar la carga de datos
    setShowUploadSpinner(true);

    // Asegurarse de que el componente se renderice antes de hacer la llamada a la API
    const fetchData = async () => {
      let companyIds = companiesSelected;
      if (auth.roles.includes("owner") && !auth.roles.includes("admin")) {
        companyIds = [{ label: "", value: auth.companyId }];
      }

      // Hacer la llamada a la API después de permitir que el spinner se muestre
      try {
        const customersSalesData = await getCustomersGraphs(auth.accesToken, companyIds, countriesSelected, servicesSelected);
        setCustomersSales(customersSalesData);
      } catch (error) {
        console.error(error);
      } finally {
        setShowUploadSpinner(false); // Quitar el spinner después de la carga
      }
    };

    fetchData(); // Ejecutar la función asíncrona
  }, [auth.accesToken, companiesSelected, countriesSelected, servicesSelected]);

  // Condición para mostrar el spinner antes de que los datos se carguen
  if (showUploadSpinner) {
    return (
      <Container fluid style={{ width: '70%', margin: '0 auto', paddingTop: '200px' }}>
        {/* Aseguramos que el spinner tenga suficiente espacio arriba */}
        <Row className="justify-content-center">
          <Col md="auto">
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (customersSales.dataByDay === undefined) return null;

  return (
    <Container fluid style={{ width: '70%', margin: '0 auto' }}>
      <h2 style={{ padding: 20, textAlign: "center" }}>{t('transactions')}</h2>
      <Row className="justify-content-center mb-3">
        <Col md="auto">
          <PortabilitySearchBar
            companiesSelected={companiesSelected}
            setCompaniesSelected={setCompaniesSelected}
            countriesSelected={countriesSelected}
            setCountriesSelected={setCountriesSelected}
            servicesSelected={servicesSelected}
            setServicesSelected={setServicesSelected}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="daily" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="daily" title={t('daily')}>
          <Chart
            data={customersSales.dataByDay}
            xlabel='date'
            t={t}
            i18n={i18n}
            type={'daily'}
          />
        </Tab>
        <Tab eventKey="weekly" title={t('weekly')}>
          <Chart
            data={customersSales.dataByWeek}
            xlabel='week'
            t={t}
            i18n={i18n}
            type={'weekly'}
          />
        </Tab>
        <Tab eventKey="monthly" title={t('monthly')}>
          <Chart
            data={customersSales.dataByMonth}
            xlabel='month'
            t={t}
            i18n={i18n}
            type={'monthly'}
          />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default CustomersSales;
