import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PricesTable from 'components/prices/PricesTable';
import Pagination from 'components/controls/Pagination';
import PricesSearchBar from 'components/controls/searchBars/PricesSearchBar';
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from 'context/TranslationProvider';
import getPrices from 'services/getPrices';

const ShowPrices = () => {
  const auth = useContext(AuthProvider);
  const navigate = useNavigate();
  const { t } = useContext(TranslationProvider);

  const [prices, setPrices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [servicesSelected, setServicesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);

  useEffect(() => {
    let companyIds = companiesSelected.map(company => company.value);
    const countryIds = countriesSelected.map(country => country.value);
    const serviceIds = servicesSelected.map(service => service.value);

    if (auth.roles.includes("owner") && !auth.roles.includes("admin")) {
      companyIds = [auth.companyId];
    }

    /* eslint-disable */
    getPrices(auth.accessToken, currentPage, perPage, companyIds, countryIds, serviceIds)
      .then(response => {
        setPrices(response.data);
        setLastPage(response.last_page);
        setPerPage(response.per_page);
      })
      .catch(() => {
        navigate('/');
        window.sessionStorage.setItem('loggedNewIntranetAppUser', '');
        window.location.reload();
      });
  }, [currentPage, companiesSelected, countriesSelected, servicesSelected]);

  const paginate = (page) => {
    setCurrentPage(page);
  };

  return (
    <Container fluid style={{ width: '70%', margin: '0 auto' }}>
      <div style={{ marginBottom: "30px", marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>
          {t('prices.priceDetail')}
        </span>
      </div>
      <Row className="justify-content-center mb-3">
        <Col md="auto">
          <PricesSearchBar
            companiesSelected={companiesSelected}
            setCompaniesSelected={setCompaniesSelected}
            servicesSelected={servicesSelected}
            setServicesSelected={setServicesSelected}
            countriesSelected={countriesSelected}
            setCountriesSelected={setCountriesSelected}
            setCurrentPage={setCurrentPage}
          />
        </Col>
      </Row>
      <PricesTable prices={prices} />
      <Pagination
        lastPage={lastPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </Container>
  );
};

export default ShowPrices;
