import { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
//components
import ServersTable from 'components/digitalOcean/servers/ServersTable'
import DatabasesTable from 'components/digitalOcean/servers/DatabasesTable'

//context - config
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from "context/TranslationProvider"
// services
import getServers from 'services/getServers'
import getDatabases from 'services/getDatabases'
// utils
//import { formatUsaPrice } from 'utils/formattingNumbers';

const ShowRecords = () => {

  const auth = useContext(AuthProvider)
  const { t } = useContext(TranslationProvider)
  const navigate = useNavigate()

  const [servers, setServers] = useState([])
  //const [serversMonthlyPrice, setServersMonthlyPrice] = useState(0)
  //const [dbsMonthlyPrice, setDbsMonthlyPrice] = useState(0)
  const [databases, setDatabases] = useState([])

  //const formattedServersPrice = formatUsaPrice(serversMonthlyPrice);
  //const formattedDatabasesPrice = formatUsaPrice(dbsMonthlyPrice);

  /* eslint-disable */
  useEffect(() => {
    getServers(auth.accesToken)
      .then(response => {
        setServers(response.droplets)
        //setServersMonthlyPrice(response.total_price_monthly)
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
    getDatabases(auth.accesToken)
      .then(response => {
        setDatabases(response.databases)
        //setDbsMonthlyPrice(response.total_price_monthly)
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [])


  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{t("digitalOcean.servers")}</span>
      </div>
      <div style={{ margin: "0 40px" }}> {/* Añadir margen horizontal */}
        <ServersTable servers={servers} />
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{t("digitalOcean.databases")}</span>
      </div>
      <div style={{ margin: "0 40px" }}> {/* Añadir margen horizontal */}
        <DatabasesTable
          databases={databases}
        />
      </div>
      <div style={{ margin: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.25em" }}>1 Volume (50 GB ), 1 Firewall, 1 Load Balancer, 1 Fix IP: &nbsp;
          <span style={{ fontSize: "1.3em" }}>  30 $/mo </span>
        </span>
      </div>
    </div>
  )
}

export default ShowRecords

