import styled from '@emotion/styled';

const calculateTrStyle = props => {
  switch (props.type) {
    case 'summary': return `
      background-color: var(--theme-body-bg);
      color: black;
      font-weight: bold;
      text-align: right;
      border-bottom: 1px var(--theme-body-bg);
    `;
    case 'splitter': return `
      border-bottom: 1px solid #000;
    `;
    case 'number': return `
      text-align: right;
    `;
    case 'center': return `
      text-align: center;
    `;
    default: return `
      text-align: left;
    `;
  }
};

const calculateTdStyle = props => {
  switch (props.type) {
    case 'number': return `
      text-align: right;
    `;
    case 'center': return `
      text-align: center;
    `;
    default:
  }
};

const isAlert = props => {
  switch (props.type) {
    case 'alert': return `
      background-color: #f2c2c2;
    `;
    default:
  }
};

export const Tbody = styled.tbody``;

export const Th = styled.th``;

export const Tr = styled.tr`
  ${isAlert}
  ${calculateTrStyle}
`;

export const Td = styled.td`
  ${isAlert}
  ${calculateTdStyle}
`;

export const Thead = styled.thead`
  background-color: #0d6efd;
  color: white;
`;

export const Tfoot = styled.tfoot`
  background-color: #f9f9f9;
  font-weight: bold;
`;
