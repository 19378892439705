import { URL } from './urls';

export default function updateCompany(accesToken, id, data) {
  const apiURL = `${URL}companies/${id}`;

  return fetch(apiURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    });
}
