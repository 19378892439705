import React from 'react'
import Table from 'react-bootstrap/Table'
//import { Link } from 'react-router-dom'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const NumericPlanTable = (props) => {

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>ida</Th>
            <Th>number_from</Th>
            <Th>number_to</Th>
            <Th>isMPP</Th>
            <Th>network_type</Th>
            <Th>ida_of_crv</Th>
            <Th>abi</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.numbersPorted.map((record) => (
            <Tr key={record.id}>
              <Td type="center"> {record.ida} </Td>
              <Td type="center"> {record.number_from} </Td>
              <Td type="center"> {record.number_to} </Td>
              <Td type="center"> {record.is_mpp} </Td>
              <Td type="center"> {record.network_type} </Td>
              <Td type="center"> {record.ida_of_crv} </Td>
              <Td type="center"> {record.abi} </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

    </div>
  )
}

export default NumericPlanTable