import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
//import { Link } from 'react-router-dom'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"
import TranslationProvider from "context/TranslationProvider"

const ServersTable = (props) => {
  const { t } = useContext(TranslationProvider)

  // Función para formatear el tamaño de la memoria
  const formatMemorySize = (kb) => {
    const units = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let size = kb;
    let unitIndex = 0;

    // Convertir el tamaño a la unidad apropiada
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size} ${units[unitIndex]}`;
  };

  // Calcular el coste total
  const totalCost = props.servers.reduce((total, server) => total + server.price_monthly, 0);

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>{t("digitalOcean.number")}</Th>
            <Th>{t("digitalOcean.name")}</Th>
            <Th>{t("digitalOcean.slug")}</Th>
            <Th>{t("digitalOcean.memory")}</Th>
            <Th>{t("digitalOcean.vcpus")}</Th>
            <Th>{t("digitalOcean.disk")}</Th>
            <Th>{t("digitalOcean.private_ip")}</Th>
            <Th>{t("digitalOcean.public_ip")}</Th>
            <Th>{t("digitalOcean.cost")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.servers.map((server, index) => (
            <Tr key={server.id}>
              <Td type="center" style={{ padding: "3px" }}>{index + 1}</Td>
              <Td type="center" style={{ padding: "3px" }}>{server.name.replace(/\s+/g, '')}</Td>
              <Td type="center" style={{ padding: "3px" }}>{server.slug}</Td>
              <Td type="center" style={{ padding: "3px" }}>{formatMemorySize(server.memory * 1024)}</Td>
              <Td type="center" style={{ padding: "3px" }}>{server.vcpus}</Td>
              <Td type="center" style={{ padding: "3px" }}>{server.disk} GB</Td>
              <Td type="center" style={{ padding: "3px" }}>{server.private_ip}</Td>
              <Td type="center" style={{ padding: "3px" }}>{server.public_ip}</Td>
              <Td type="center" style={{ padding: "3px" }}>{server.price_monthly} &nbsp; $/{t("month")}</Td>
            </Tr>
          ))}
          <Tr>
            <Td type="center" colSpan="8"><strong>Total</strong></Td>
            <Td type="center"><strong>{totalCost} &nbsp; $/{t("month")}</strong></Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  )
}

export default ServersTable
