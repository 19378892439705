import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SalesTable from 'components/salesSummary/SalesTable';
import Pagination from 'components/controls/Pagination';
import PortabilitySearchBar from 'components/controls/searchBars/PortabilitySearchBar';
import AuthProvider from 'context/AuthProvider';
import TranslationProvider from 'context/TranslationProvider';
import getSalesSummary from 'services/getSalesSummary';
import useSinceUntilDatePicker from 'hooks/useSinceUntilDatePicker';
import { salesInitialStartDate, salesInitialEndDate } from 'config/sinceUntilDatePicker';

const SalesSummary = () => {
  const auth = useContext(AuthProvider);
  const navigate = useNavigate();
  const { t } = useContext(TranslationProvider);

  const [sales, setSales] = useState([]);
  const [totals, setTotals] = useState({ total_transactions: 0, total_sales: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [servicesSelected, setServicesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);

  const { startDate, endDate, changeStartDate, changeEndDate } = useSinceUntilDatePicker({
    initialStartDate: salesInitialStartDate,
    initialEndDate: salesInitialEndDate,
  });

  /* eslint-disable */
  useEffect(() => {
    let companyIds = companiesSelected.map(company => company.value);
    const countryIds = countriesSelected.map(country => country.value);
    const serviceIds = servicesSelected.map(service => service.value);
    if (auth.roles.includes("owner") && !auth.roles.includes("admin")) {
      // console.log(auth)
      companyIds = [auth.companyId]
    }

    getSalesSummary(auth.accesToken, currentPage, perPage, companyIds, countryIds, serviceIds, startDate, endDate)
      .then(response => {
        //console.log('Response Totals:', response.totals); // Verificar los datos recibidos
        setSales(response.data.data);
        setTotals(response.totals || { total_transactions: 0, total_sales: 0 });
        setLastPage(response.data.last_page);
        setPerPage(response.data.per_page);
      })
      .catch(() => {
        navigate('/');
        window.sessionStorage.setItem('loggedNewIntranetAppUser', '');
        window.location.reload();
      });
  }, [currentPage, companiesSelected, countriesSelected, servicesSelected, startDate, endDate]);

  const paginate = (currentPage) => {
    setCurrentPage(currentPage);
  }

  return (
    <Container fluid style={{ width: '70%', margin: '0 auto' }}>
      <div style={{ marginBottom: "30px", marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.75em" }}>{auth.roles.includes("admin") ? t('sales.salesSummary') : t('sales.buySummary')}</span>
      </div>
      <Row className="justify-content-center mb-3">
        <Col md="auto">
          <PortabilitySearchBar
            companiesSelected={companiesSelected}
            setCompaniesSelected={setCompaniesSelected}
            servicesSelected={servicesSelected}
            setServicesSelected={setServicesSelected}
            countriesSelected={countriesSelected}
            setCountriesSelected={setCountriesSelected}
            showDatePicker={true}
            startDate={startDate}
            changeStartDate={changeStartDate}
            endDate={endDate}
            changeEndDate={changeEndDate}
            setCurrentPage={setCurrentPage}
          />
        </Col>
      </Row>
      <SalesTable sales={sales} totals={totals} />
      <Pagination
        lastPage={lastPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </Container>
  );
};

export default SalesSummary;
