import React, { useState, useEffect, useContext } from 'react';
import Select from "react-select";
import AuthProvider from 'context/AuthProvider';
import getCompaniesForFilter from 'services/getCompaniesForFilter';
import TranslationProvider from 'context/TranslationProvider';

const CompaniesFilter = ({ companiesSelected, setCompaniesSelected }) => {
  const auth = useContext(AuthProvider);
  const [companies, setCompanies] = useState([]);
  const { t } = useContext(TranslationProvider);

  useEffect(() => {
    getCompaniesForFilter(auth.accesToken).then(companies => {
      const formattedCompanies = companies.map(company => ({
        label: company.name,
        value: company.id
      }));
      setCompanies(formattedCompanies);
    });
  }, [auth.accesToken]);

  const handleCompaniesChange = (selectedOptions) => {
    setCompaniesSelected(selectedOptions);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: '150px',
    }),
  };

  return (
    <Select
      options={companies}
      onChange={handleCompaniesChange}
      value={companiesSelected}
      styles={customStyles}
      placeholder={t('companies.companies')}
      isMulti
    />
  );
};

export default CompaniesFilter;
