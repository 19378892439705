import React from 'react'
import Table from 'react-bootstrap/Table'
//import { Link } from 'react-router-dom'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const RecordsTable = (props) => {

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>PortID</Th>
            <Th>PortType</Th>
            <Th>Action</Th>
            <Th>TN</Th>
            <Th>isMPP</Th>
            <Th>RIDA</Th>
            <Th>RCR</Th>
            <Th>DIDA</Th>
            <Th>DCR</Th>
            <Th>AssigneeIDA</Th>
            <Th>AssigneeCR</Th>
            <Th>ActionDate</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.numbersPorted.map((record) => (
            <Tr key={record.id}>
              <Td type="center"> {record.port_id} </Td>
              <Td type="center"> {record.port_type} </Td>
              <Td type="center"> {record.action} </Td>
              <Td type="center"> {record.telephone_number} </Td>
              <Td type="center"> {record.is_mpp} </Td>
              <Td type="center"> {record.rida} </Td>
              <Td type="center"> {record.rcr} </Td>
              <Td type="center"> {record.dida} </Td>
              <Td type="center"> {record.dcr} </Td>
              <Td type="center"> {record.assignee_ida} </Td>
              <Td type="center"> {record.assignee_cr} </Td>
              <Td type="center"> {record.action_date} </Td>
              {/* <Td style={{  "backgroundColor": "transparent" }}> </Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>

    </div>
  )
}

export default RecordsTable