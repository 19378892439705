import React, { useContext, useEffect } from 'react'
import TranslationProvider from 'context/TranslationProvider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { FormLabel, FormButton2, FormError, FormHead } from "styles/form"
import Select from "react-select";
import Spinner from 'react-bootstrap/Spinner'

const EditUserForm = ({ formParameters, setFormParameters, goBack, update, errMsg, showSpinner }) => {

  const { t } = useContext(TranslationProvider)

  const schema = yup.object({
    name: yup.string().min(2).required(),
    phone: yup.string().matches(/^\d{9,}$/, "The phone must have at least 9 digits").required(), // Ajustamos la validación del teléfono
    email: yup.string().email().required(),
    twoFACode: yup.number().oneOf([0, 1]).required("El campo solo puede ser 0 o 1"),
  }).required()

  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    let defaultValues = {}
    defaultValues.name = formParameters.name
    defaultValues.phone = formParameters.phone
    defaultValues.email = formParameters.email
    defaultValues.language = formParameters.language
    defaultValues.twoFACode = formParameters.twoFACode
    reset({ ...defaultValues })
  }, [reset, formParameters])


  const onSubmit = () => update()

  return (
    <div style={{ padding: 20 }}>
      {showSpinner &&
        <Spinner
          animation="border" variant="primary"
          style={{ "display": "grid", "margin": "auto" }}
        />}
      <FormHead>{t('users.userEdit')}</FormHead>

      <div>
        <FormLabel>{t('users.name')}</FormLabel>
        <input
          {...register("name")}
          value={formParameters.name}
          onChange={(e) => setFormParameters({
            ...formParameters,
            name: e.target.value
          })}
          type='text'
          className='form-control'
        />
        {errors.name && <FormError mError>{errors.name.message}</FormError>}
      </div>

      <div>
        <FormLabel>{t('users.phone')}</FormLabel>
        <input
          {...register("phone")}
          value={formParameters.phone}
          onChange={(e) => setFormParameters({
            ...formParameters,
            phone: e.target.value
          })}
          type='number'
          className='form-control'
        />
        {errors.phone && <FormError mError>{errors.phone.message}</FormError>}
      </div>

      <div>
        <FormLabel>{t('users.email')}</FormLabel>
        <input
          {...register("email")}
          value={formParameters.email}
          onChange={(e) => setFormParameters({
            ...formParameters,
            email: e.target.value
          })}
          type='text'
          className='form-control'
        />
        {errors.email && <FormError mError>{errors.email.message}</FormError>}
      </div>

      <div>
        <FormLabel>{t('users.language')}</FormLabel>
        <Select
          options={[
            { value: 'en', label: 'en' },
            { value: 'es', label: 'es' },
          ]}
          onChange={(selectedOption) => setFormParameters({
            ...formParameters,
            language: selectedOption ? selectedOption.value : null,
          })}
          defaultValue={formParameters.language}
        />
      </div>

      <div>
        <FormLabel>{t('users.twoFACode')}</FormLabel>
        <input
          {...register("twoFACode")}
          value={formParameters.twoFACode}
          onChange={(e) => setFormParameters({
            ...formParameters,
            twoFACode: e.target.value
          })}
          type='number'
          className='form-control'
        />
        {errors.twoFACode && <FormError mError>{errors.twoFACode.message}</FormError>}
      </div>

      <h4 className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</h4>

      <FormButton2 onClick={handleSubmit(onSubmit)}>{t('update')}</FormButton2>
      <FormButton2 onClick={goBack}>{t('back')}</FormButton2>
    </div>
  )
}
export default EditUserForm