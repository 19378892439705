import { URL as BASE_URL } from './urls';

export default function getSalesDetail(accesToken, currentPage, perPage, companyIds = [], countryIds = [], serviceIds = [],
  startDate = null, endDate = null, paginate = true) {
  let apiURL = `${BASE_URL}companies/sales_detail?page=${currentPage}&per_page=${perPage}&paginate=${paginate}`;

  companyIds.forEach(id => {
    apiURL += `&company_ids[]=${id}`;
  });

  countryIds.forEach(id => {
    apiURL += `&country_ids[]=${id}`;
  });

  serviceIds.forEach(id => {
    apiURL += `&service_ids[]=${id}`;
  });

  const formatDate = (date) => {
    const d = new Date(date);
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();

    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
  };

  if (startDate) {
    apiURL += `&start_date=${formatDate(startDate)}`;
  }

  if (endDate) {
    apiURL += `&end_date=${formatDate(endDate)}`;
  }

  return fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        // Añadir un log para ver la respuesta del servidor en la consola
        res.json().then(err => console.error('Server Error:', err));
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response;
    });
}
