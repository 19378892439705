// config/sinceUntilDatePicker.js
let startDate = new Date();
startDate.setDate(startDate.getDate());

let callsEndDate = new Date();
callsEndDate.setDate(callsEndDate.getDate());

export const callsInitialStartDate = startDate;
export const callsInitialEndDate = callsEndDate;

// Configuración específica para ventas
let salesStartDate = new Date();
const daysToSubtract = 1; // Aquí puedes definir cuántos días quieres restar
salesStartDate.setDate(salesStartDate.getDate() - daysToSubtract);

let salesEndDate = new Date();
salesEndDate.setDate(salesEndDate.getDate());

export const salesInitialStartDate = salesStartDate;
export const salesInitialEndDate = salesEndDate;
