import React from "react"
import { Routes, Route } from "react-router-dom"

// pages
import ChangePassword from "pages/ChangePassword"
import Home from "pages/Home"
import SalesSummary from 'pages/Reports/Summary';
import SalesDetail from 'pages/Reports/Detail';
import ShowCustomersGraphs from "pages/Reports/Graph"
import ShowInterconnections from "pages/Admin/Interconnection"
import ShowLogins from "pages/Admin/Login"
import ShowUsers from "pages/Admin/User"
import ShowPrices from "pages/Prices"
import Countries from 'pages/Admin/Countries';
import ShowCompanies from 'pages/Admin/Companies/'; // Importa la página para mostrar las empresas
import ShowNumericPlan from "pages/Files/NumericPlan"
import ShowRecords from "pages/Files/NumbersPorted"
import ShowRecordsDeleted from "pages/Files/NumbersDeleted"
import ShowQueries from "pages/Queries"
import ShowServers from "pages/DigitalOcean/Server"
import Settings from "pages/Settings"
// components
import EditUser from "components/admin/users/EditUser"
import EditPrice from "components/prices/EditPrice"
import EditCountryForm from 'components/countries/EditCountryForm';
import EditCompanyForm from 'components/companies/EditCompanyForm'; // Importa la página para editar una empresa

const RenderRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/numeric_plan" element={<ShowNumericPlan />} />
      <Route path="/numbers_ported" element={<ShowRecords />} />
      <Route path="/numbers_deleted" element={<ShowRecordsDeleted />} />
      <Route path="/queries" element={<ShowQueries />} />
      <Route path="/admin/interconnections" element={<ShowInterconnections />} />
      <Route path="/admin/logins" element={<ShowLogins />} />
      <Route path="/admin/countries" element={<Countries />} />
      <Route path="/admin/countries/edit/:id" element={<EditCountryForm />} />
      <Route path="/admin/users" element={<ShowUsers />} />
      <Route path="/prices" element={<ShowPrices />} />
      <Route path="/users/edit/:id" element={<EditUser />} />
      <Route path="/prices/edit/:id" element={<EditPrice />} />
      <Route path="/digital_ocean/servers" element={<ShowServers />} />
      <Route path="/reports/sales_summary" element={<SalesSummary />} />
      <Route path="/reports/sales_detail" element={<SalesDetail />} />
      <Route path="/reports/sales_graphs" element={<ShowCustomersGraphs />} />
      <Route path="/admin/companies" element={<ShowCompanies />} /> {/* Ruta para mostrar las empresas */}
      <Route path="/admin/companies/edit/:id" element={<EditCompanyForm />} /> {/* Ruta para editar una empresa */}
      <Route path="/change_password" element={<ChangePassword />} />
    </Routes>
  )
}

export default RenderRoutes
