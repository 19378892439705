import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import TranslationProvider from 'context/TranslationProvider';
import { Tr, Td, Thead, Th, Tbody, Tfoot } from 'styles/table';
import AuthProvider from 'context/AuthProvider';

const SalesTable = ({ sales, totals }) => {
  const { t } = useContext(TranslationProvider);
  const auth = useContext(AuthProvider);

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            <Th>{t('sales.date')}</Th>
            <Th>{t('sales.phone')}</Th>
            {auth.roles.includes("admin") &&
              <Th>{t('sales.company')}</Th>}
            <Th>{t('sales.country')}</Th>
            <Th>{t('sales.service')}</Th>
            <Th>{t('sales.status')}</Th>
            {auth.roles.includes("admin") && <Th>{t('sales.cost')}</Th>}
            <Th>{auth.roles.includes("admin") ? t('sales.pvp') : t('sales.cost')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sales.map((sale) => {
            const tdStyle = {
              backgroundColor: sale.status !== 100 ? '#f8d7da' : 'transparent',
            };

            return (
              <Tr key={sale.id}>
                <Td type="center" style={tdStyle}>{sale.fecha}</Td>
                <Td type="center" style={tdStyle}>{sale.phone}</Td>
                {auth.roles.includes("admin") &&
                  <Td type="center" style={tdStyle}>{sale.company_name}</Td>}
                <Td type="center" style={tdStyle}>{sale.country_name}</Td>
                <Td type="center" style={tdStyle}>{sale.service_name}</Td>
                <Td type="center" style={tdStyle}>{sale.status}</Td>
                {auth.roles.includes("admin") && <Td type="center" style={tdStyle}> {sale.cost_price} €</Td>}
                <Td type="center" style={tdStyle}> {sale.selling_price} €</Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td
              colSpan={(auth.roles.includes("admin")) ? "0" : "2"}
              style={{ textAlign: "right", fontWeight: "bold" }}
            >
              {t('transactions')}:
            </Td>
            <Td type="center" style={{ textAlign: "left", fontWeight: "bold" }}>
              {totals.total_transactions.toLocaleString('en-US')}
            </Td>
            {(auth.roles.includes("admin")) && <Td
              colSpan={(auth.roles.includes("admin")) ? "0" : "2"}
              style={{ textAlign: "right", fontWeight: "bold" }}
            >
              {t('reports.profit')}:
            </Td>}
            {(auth.roles.includes("admin")) &&
              <Td type="center" style={{ textAlign: "left", fontWeight: "bold" }}>{parseFloat(totals.total_selling_price - totals.total_cost_price).toFixed(2)} €</Td>}
            <Td colSpan={(auth.roles.includes("admin")) ? "2" : "2"} type="center" style={{ textAlign: "right", fontWeight: "bold" }}></Td>
            {(auth.roles.includes("admin")) &&
              <Td type="center" style={{ textAlign: "center", fontWeight: "bold" }}>{parseFloat(totals.total_cost_price).toFixed(2)} €</Td>}
            <Td type="center" style={{ textAlign: "center", fontWeight: "bold" }}>{parseFloat(totals.total_selling_price).toFixed(2)} €</Td>
          </Tr>
        </Tfoot>
      </Table>
    </div>
  );
};

export default SalesTable;
