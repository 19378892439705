import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import TranslationProvider from 'context/TranslationProvider'
import { Tr, Td, Thead, Th, Tbody } from "styles/table"

const InterconnectionsTable = (props) => {

  const { t } = useContext(TranslationProvider)

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            <Th>{t('users.company')}</Th>
            <Th>{t('users.name')}</Th>
            <Th>Interconnection {t('users.email')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.users.map((user) => (
            <Tr key={user.id}>
              <Td type="center"> {user.company_name} </Td>
              <Td type="center"> {user.name} </Td>
              <Td type="center"> {user.email} </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default InterconnectionsTable