import { URL } from './urls';

export default function getPrices(accessToken, currentPage, perPage, companySelected = [], countrySelected = [], serviceSelected = []) {
  // Construimos la URL base con la paginación
  let apiURL = `${URL}prices?page=${currentPage}&per_page=${perPage}`;

  // Añadimos los filtros como arrays si existen valores
  companySelected.forEach(id => {
    apiURL += `&companySelected[]=${id}`;
  });

  countrySelected.forEach(id => {
    apiURL += `&countrySelected[]=${id}`;
  });

  serviceSelected.forEach(id => {
    apiURL += `&serviceSelected[]=${id}`;
  });

  return fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        res.json().then(err => console.error('Server Error:', err));
        throw new Error("Server responds with error!");
      }
      return res.json();
    })
    .then(response => {
      return response;
    });
}
