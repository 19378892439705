import React from "react"
import { Link } from "react-router-dom"
import { Nav, NavDropdown } from "react-bootstrap"
import SharedMenu from "./SharedMenu"  // Importa el nuevo componente

const RenderBrowserRouterOthers = ({ t }) => {
  return (
    <Nav className="me-auto" style={{ marginLeft: "20px" }}>
      <NavDropdown title={t("reports.reports")}>
        <NavDropdown.Item as={Link} to="/reports/sales_summary">
          {t("reports.buySummary")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/reports/sales_detail">
          {t("reports.buyDetail")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/reports/sales_graphs">
          {t("reports.buyGraphs")}
        </NavDropdown.Item>
      </NavDropdown>
      <SharedMenu t={t} />  {/* Menú común agregado aquí */}
    </Nav>
  )
}

export default RenderBrowserRouterOthers
