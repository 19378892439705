import React from "react"
import { Link } from "react-router-dom"
import { Nav, NavDropdown } from "react-bootstrap"
import SharedMenu from "./SharedMenu"  // Importa el nuevo componente

const RenderBrowserRouterAdmin = ({ t }) => {
  return (
    <Nav className="me-auto" style={{ marginLeft: "20px" }}>
      <NavDropdown title={t("reports.reports")}>
        <NavDropdown.Item as={Link} to="/reports/sales_summary">
          {t("reports.salesSummary")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/reports/sales_detail">
          {t("reports.salesDetail")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/reports/sales_graphs">
          {t("reports.salesGraphs")}
        </NavDropdown.Item>
      </NavDropdown>
      <SharedMenu t={t} />  {/* Menú común agregado aquí */}
      {/* <NavDropdown title={t("portability.files")}>
        <NavDropdown.Item as={Link} to="/numeric_plan">
          {t("portability.numericPlan")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/numbers_ported">
          {t("portability.numbersPorted")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/numbers_deleted">
          {t("portability.numbersDeleted")}
        </NavDropdown.Item>
      </NavDropdown> */}
      <Nav.Link as={Link} to="/queries">
        {t("queries")}
      </Nav.Link>
      <NavDropdown title="Digital Ocean">
        <NavDropdown.Item as={Link} to="/digital_ocean/servers">
          {t("digitalOcean.servers")}
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title={t("admin.admin")}>
        <NavDropdown.Item as={Link} to="/admin/companies">
          {t("admin.companies")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin/countries">
          {t("admin.countries")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin/users">
          {t("users.users")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin/interconnections">
          {t("interconnections")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin/logins">
          {t("admin.logins")}
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  )
}

export default RenderBrowserRouterAdmin
