import { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//components
import QuerySearchBar from 'components/queries/QuerySearchBar'
import ResponseDataTables from 'components/queries/ResponseDataTables'; // Cambia el nombre del componente para que represente las tablas
//context - config
import AuthProvider from 'context/AuthProvider'
// services
import queryDb from 'services/queryDb'
// styles
import 'components/queries/styles.css'; 

const ShowQueries = () => {
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const [queryresult, setQueryResult] = useState({})
  const [numberToQuery, setNumberToQuery] = useState('525650821865')
  const [serviceSelected, setServiceSelected] = useState("mnp");
  const [sendQuery, setSendQuery] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    if (sendQuery && numberToQuery) {
      queryDb(auth.accesToken, numberToQuery, serviceSelected)
        .then(response => {
          setQueryResult(response)
        })
        .catch(() => {
          navigate('/')
          window.sessionStorage.setItem(
            'loggedNewIntranetAppUser', ''
          )
          window.location.reload()
        })
      setSendQuery(false)
    }
  }, [sendQuery])

  return (
    <Container fluid style={{ width: '70%', margin: '0 auto', marginTop: '25px' }}>
      <Row className="justify-content-center mb-3">
        <Col md="auto">
          <QuerySearchBar
            numberToQuery={numberToQuery}
            setNumberToQuery={setNumberToQuery}
            setServiceSelected={setServiceSelected}
            serviceSelected={serviceSelected}
            setSendQuery={setSendQuery}
            setQueryResult={setQueryResult}
          />
        </Col>
      </Row>
      <div className="table-wrapper">
        <ResponseDataTables queryresult={queryresult} />
      </div>
    </Container>
  )
}

export default ShowQueries
