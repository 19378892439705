import { URL } from './urls';

export default function getCompanyById(accesToken, id) {
  const apiURL = `${URL}companies/${id}`;

  return fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accesToken}`
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error!");
      }
      return res.json();
    });
}
