import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import TranslationProvider from 'context/TranslationProvider';
import { Tr, Td, Thead, Th, Tbody } from 'styles/table';
import { AiFillEdit } from 'react-icons/ai';

const CompaniesTable = ({ companies }) => {
  const { t } = useContext(TranslationProvider);

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            <Th>{t('companies.name')}</Th>
            <Th>{t('companies.legal_name')}</Th>
            <Th>{t('companies.tax_id')}</Th>
            <Th>{t('locations.address1')}</Th>
            <Th>{t('locations.city')}</Th>
            <Th>{t('locations.state')}</Th>
            <Th>{t('locations.zip_code')}</Th>
            <Th>{t('locations.country')}</Th>
            <Th>{t('locations.phone')}</Th>
            <Th>{t('actions.edit')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {companies.map((company) => (
            <Tr key={company.id}>
              <Td type="center"> {company.name} </Td>
              <Td type="center"> {company.legal_name} </Td>
              <Td type="center"> {company.tax_id} </Td>
              <Td type="center"> {company.location.address1} </Td>
              <Td type="center"> {company.location.city} </Td>
              <Td type="center"> {company.location.state} </Td>
              <Td type="center"> {company.location.zip_code} </Td>
              <Td type="center"> {company.location.country} </Td>
              <Td type="center"> {company.location.contact_number} </Td>
              <Td type="center">
                <Link to={`/admin/companies/edit/${company.id}`}>
                  <AiFillEdit />
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default CompaniesTable;
