import React, { useContext } from 'react';
//import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import TranslationProvider from 'context/TranslationProvider';
import { Tr, Td, Thead, Th, Tbody } from "styles/table";
//import { AiFillEdit } from "react-icons/ai";
import AuthProvider from 'context/AuthProvider';

const PricesTable = (props) => {
  const { t } = useContext(TranslationProvider);
  const auth = useContext(AuthProvider);

  return (
    <div>
      <Table striped responsive>
        <Thead className='bg-primary text-white'>
          <Tr type="center">
            {auth.roles.includes("admin") && <Th>{t('prices.company')}</Th>}
            <Th>{t('prices.country')}</Th>
            <Th>{t('prices.service')}</Th>
            <Th>{t('prices.costPrice')}</Th>
            {auth.roles.includes("admin") && <Th>{t('prices.sellingPrice')}</Th>}
            <Th>{t('prices.since')}</Th>
            <Th>{t('prices.until')}</Th>
            {/* <Th>{t('actions.edit')}</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {props.prices.map((price) => (
            <Tr key={price.id}>
              {auth.roles.includes("admin") && <Td> {price.company.name} </Td>}
              <Td> {price.country.country_name} </Td>
              <Td> {price.service.name} </Td>
              {auth.roles.includes("admin") && <Td type="center"> {price.cost_price} </Td>}
              <Td type="center"> {price.selling_price} </Td>
              <Td> {price.since} </Td>
              <Td> {price.until} </Td>
              {/* <Td>
                <Link to={`/prices/edit/${price.id}`}>
                  <AiFillEdit />
                </Link>
              </Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default PricesTable;
