import { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
//components
import NumericPlanTable from 'components/numericPlan/NumericPlanTable'
import Pagination from 'components/controls/Pagination'

//context - config
import AuthProvider from 'context/AuthProvider'
import TranslationProvider from "context/TranslationProvider"
// services
import getNumericPlan from 'services/getNumericPlan'

const ShowNumericPlan = () => {

  const auth = useContext(AuthProvider)
  const navigate = useNavigate()
  const { t } = useContext(TranslationProvider)

  const [numbersPorted, setRecords] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [recordsNumber, setRecordsNumber] = useState(0)

  /* eslint-disable */
  useEffect(() => {
    getNumericPlan(auth.accesToken, currentPage, perPage)
      .then(response => {
        setRecords(response.data)
        setLastPage(response.meta.last_page)
        setPerPage(response.meta.per_page)
        setRecordsNumber(response.meta.total)
      })
      .catch(() => {
        navigate('/')
        window.sessionStorage.setItem(
          'loggedNewIntranetAppUser', ''
        )
        window.location.reload()
      })
  }, [currentPage])

  const paginate = (currentPage) => {
    setCurrentPage(currentPage)
  }


  return (
    <div>
      <div style={{ padding: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: "1.5em", marginRight: "100px" }}>{t("portability.numericPlan")}</span>
        <span style={{ fontSize: "1.5em" }}>Total: {recordsNumber.toLocaleString('es-ES')}</span>
      </div>

      <NumericPlanTable
        numbersPorted={numbersPorted}
      />
      <Pagination
        lastPage={lastPage}
        currentPage={currentPage}
        paginate={paginate}
      />
    </div>
  )
}

export default ShowNumericPlan

