import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import TranslationProvider from 'context/TranslationProvider';
import { Tr, Td, Thead, Th, Tbody, Tfoot } from 'styles/table';
import AuthProvider from 'context/AuthProvider';

const SalesTable = ({ sales, totals }) => {
  const { t } = useContext(TranslationProvider);
  const auth = useContext(AuthProvider);

  return (
    <div>
      <Table striped responsive>
        <Thead>
          <Tr type="center">
            {(auth.roles.includes("admin")) &&
              <Th>{t('sales.company')}</Th>}
            <Th>{t('sales.country')}</Th>
            <Th>{t('sales.service')}</Th>
            <Th>{t('sales.transactions')}</Th>
            {auth.roles.includes("admin") && <Th>{t('sales.cost')}</Th>}
            <Th>{auth.roles.includes("admin") ? t('sales.pvp') : t('sales.cost')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sales.map((sale) => (
            <Tr key={`${sale.company_id}-${sale.country_id}-${sale.service_id}`}>
              {(auth.roles.includes("admin")) &&
                <Td type="center"> {sale.company_name} </Td>}
              <Td type="center"> {sale.country_name} </Td>
              <Td type="center"> {sale.service_name}</Td>
              <Td type="center"> {parseInt(sale.transactions, 10)} </Td>
              {auth.roles.includes("admin") && <Td type="center"> {parseFloat(sale.cost_price).toFixed(2)} </Td>}
              <Td type="center"> {parseFloat(sale.selling_price).toFixed(2)} </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan={(auth.roles.includes("admin")) ? "1" : "2"}></Td>
            {(auth.roles.includes("admin")) && <Td
              colSpan={(auth.roles.includes("admin")) ? "0" : "2"}
              style={{ textAlign: "right", fontWeight: "bold" }}
            >
              {t('reports.profit')}:
            </Td>}
            {(auth.roles.includes("admin")) &&
              <Td type="center" style={{ textAlign: "left", fontWeight: "bold" }}>{parseFloat(totals.total_selling_price - totals.total_cost_price).toFixed(2)} €</Td>}
            <Td type="center" style={{ fontWeight: "bold" }}>{Number(totals.total_transactions).toLocaleString('en-US')}</Td>
            {auth.roles.includes("admin") && <Td type="center" style={{ fontWeight: "bold" }}>{parseFloat(totals.total_cost_price).toFixed(2)}</Td>}
            <Td type="center" style={{ fontWeight: "bold" }}>{parseFloat(totals.total_selling_price).toFixed(2)}</Td>
          </Tr>
        </Tfoot>
      </Table>
    </div>
  );
};

export default SalesTable;
