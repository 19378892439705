import { useEffect, useState } from 'react'
import getCustomers from 'services/getCustomers'

export default function useGetCustomers(accesToken) {

  const [customers, setCustomers] = useState([])

  useEffect(() => {
    getCustomers(accesToken).then(customers => setCustomers(customers.data))
  }, [accesToken])



  return {
    customers
  }
} 