import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import { AiOutlineLogout } from "react-icons/ai"
import logo from "logo.svg"
//context
import AuthProvider from "context/AuthProvider"
import TranslationProvider from "context/TranslationProvider"
// hooks
import { useNavigate } from "react-router-dom"

import RenderRoutes from "./RenderRoutes"
import RenderBrowserRouterAdmin from "./RenderBrowserRouterAdmin"
import RenderBrowserRouterOthers from "./RenderBrowserRouterOthers"

const RenderBrowserRouter = ({ changeLanguage }) => {
  const { i18n } = useContext(TranslationProvider)
  const { t } = useContext(TranslationProvider)
  const auth = useContext(AuthProvider)
  const navigate = useNavigate()

  const logout = (() => {
    navigate('/')
    window.sessionStorage.setItem(
      'loggedNewIntranetAppUser', ''
    )
    window.location.reload()
  })

  return (
    <div className="App">
      <Navbar
        bg="dark"
        variant="dark"
        sticky="top"
        expand="sm"
        collapseOnSelect
      >
        <Navbar.Brand as={Link} to="/">
          <img alt="logo" src={logo} width="40px" height="40px" />{" "}
          {t("home")}
        </Navbar.Brand>

        <Navbar.Toggle className="coloring" />
        <Navbar.Collapse>
          {auth.roles.includes("admin") && (
            <RenderBrowserRouterAdmin t={t} />
          )}
          {auth.roles.includes("owner") && !auth.roles.includes("admin") && (
            <RenderBrowserRouterOthers t={t} />
          )}

          <Nav className="justify-content-end" style={{ marginRight: "80px" }}>
            <NavDropdown title={i18n.language}>
              <NavDropdown.Item onClick={() => changeLanguage("es")}>
                Español
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeLanguage("en")}>
                English
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={auth.userName}>
              <NavDropdown.Item as={Link} to="/change_password">
                {t("change_password.change_password_form")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/settings">
                {t("settings")}
              </NavDropdown.Item>
              <NavDropdown.Item as={Button} onClick={logout}>
                <AiOutlineLogout style={{ marginRight: 5 }} /> {t("signOut")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <RenderRoutes />
    </div>
  )
}

export default RenderBrowserRouter
