import React, { useState, useEffect, useContext } from 'react';
import Select from "react-select";
import AuthProvider from 'context/AuthProvider';
import getServicesForFilter from 'services/getServicesForFilter';
import TranslationProvider from 'context/TranslationProvider';

const ServicesFilter = ({ servicesSelected, setServicesSelected }) => {
  const auth = useContext(AuthProvider);
  const [services, setServices] = useState([]);
  const { t } = useContext(TranslationProvider);

  useEffect(() => {
    getServicesForFilter(auth.accesToken).then(services => {
      const formattedServices = services.map(company => ({
        label: company.name,
        value: company.id
      }));
      setServices(formattedServices);
    });
  }, [auth.accesToken]);

  const handleServicesChange = (selectedOptions) => {
    setServicesSelected(selectedOptions);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: '150px',
    }),
  };

  return (
    <Select
      options={services}
      onChange={handleServicesChange}
      value={servicesSelected}
      styles={customStyles}
      placeholder={t('services')}
      isMulti
    />
  );
};

export default ServicesFilter;
